import './polyfills';

import '../assets/images/icon-plus.svg';

import SwupScrollPlugin from '@swup/scroll-plugin';

import Swup from 'swup';
const options = {
  animateHistoryBrowsing: true,
  animationSelector: '[class*="transition-"]',
  containers: ["#swup"],
  cache: true,
  linkSelector:
    'a[href^="' +
    window.location.origin +
    '"]:not([data-no-swup]), a[href^="/"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])',
  skipPopStateHandling: function(event) {
    if (event.state && event.state.source == "swup") {
      return false;
    }
    return true;
  },
  plugins: [new SwupScrollPlugin()]
};
const swup = new Swup(options);
